import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import ThemisInput from "@/components/shared/input"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { TABLE_NAMES, MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name      : "Screens",
  components: {
    ThemisInput
  },
  data: () => ({
    showAddScreenDialog     : false,
    screenNameCharacterLimit: MAX_CHARACTER_LIMIT.SCREEN_NAME,
    isScreenNameDuplicate   : false,
    isScreenNameFocused     : false,
    screen                  : {
      name: null
    }
  }),
  methods: {
    ...mapActions({
      addScreen: "screens/addScreen",
      notify   : "shared/notify"
    }),
    ...mapMutations({
      setScreenAddError: "screens/setScreenAddError"
    }),
    handleCancelAddScreen() {
      this.screen.name         = null
      this.showAddScreenDialog = false
    },
    handleAddScreen() {
      this.addScreen({
        name: this.screen.name
      })
    },
    handleScreenNameInputOnBlurEvent(onBlur) {
      onBlur()
      this.isScreenNameFocused = false
    },
    handleScreenNameInputOnFocusEvent(onFocus) {
      onFocus()
      this.isScreenNameFocused = true
    }
  },
  computed: {
    ...mapGetters({
      workflows         : "workflows/workflows",
      isWorkflowsEnabled: "configurations/isWorkflowsEnabled",
      screens           : "screens/screens",
      isAddingScreen    : "screens/isAddingScreen",
      isScreenAdded     : "screens/isScreenAdded",
      screenAddError    : "screens/screenAddError"
    }),
    screensHelpCenterUrl() {
      return process.env.VUE_APP_SCREENS_HELP_URL
    },
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.SCREENS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.SCREENS, this.$t.bind(this))
    },
    screensForTable() {
      return this.screens.map(screens => {
        return {
          id            : screens.id,
          name          : screens.name,
          workflowUsedIn: this.screenWorkflowNameMap[screens.id]
            ? [...this.screenWorkflowNameMap[screens.id]].join(", ")
            : ""
        }
      })
    },
    screenWorkflowNameMap() {
      const screenWorkflowNameMap = {}
      this.workflows.forEach(workflow => {
        workflow.transitions.forEach(transition => {
          if (transition.screen) {
            if (!screenWorkflowNameMap[transition.screenId]) {
              screenWorkflowNameMap[transition.screenId] = new Set()
            }
            screenWorkflowNameMap[transition.screenId].add(workflow.name)
          }
        })
      })
      return screenWorkflowNameMap
    },
    isScreenNameValid() {
      return this.screen.name &&
        this.screen.name.length <= this.screenNameCharacterLimit
    }
  },
  watch: {
    "screen.name": {
      handler: function() {
        if (this.isScreenNameDuplicate) {
          this.setScreenAddError(null)
        }
      }
    },
    screenAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isScreenNameDuplicate = true
        } else {
          this.isScreenNameDuplicate = false
        }
      }
    },
    isScreenAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.showAddScreenDialog = false
          this.screen.name         = null
          this.notify({
            type: "success",
            text: "1482"
          })
        }
      }
    }
  }
}